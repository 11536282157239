import React, { useContext, useState } from 'react'
import Layout from "../components/layout"
import CartContext from '../context/CartContext'
import Img from "gatsby-image"
import { slugify } from '../helpers'
import Checkout from '../components/checkout'
import {Link} from 'gatsby'
import usa from '../images/usa.svg'


const Cart = (props) => {
  const { state, clearCart, removeItem } = useContext(CartContext)
  const [ country, setCountry ] = useState('')


  // console.log(country)
  return (
    <Layout headerData={props}>
      <div className="max-w-2xl mx-auto rounded flex items-center justify-center px-2 ">
        <p className="mr-2">For the moment we are shipping only to </p> <img className="w-4 h-4 mr-1" src={usa} alt="usa" /><p>USA</p>
      </div>
      <div className="max-w-2xl mx-auto rounded flex items-center justify-center px-2 mt-2">
        <p className="mr-2">Pick your country</p>

        <select className="bg-brand-100 px-4 rounded font-bold text-lg" onChange={(e) => setCountry(e.target.value)}>
          <option value="chooose-country">Choose a country</option>
          <option value="usa">USA</option>
          {/* <option value="eu">Europe</option> */}
          {/* <option value="asia">Asia & Oceania</option> */}
        </select>
      </div>

      <div className="max-w-2xl mx-auto rounded mb-8 px-2">
        <div className="w-full flex items-center mt-8 md:mt-16 mb-8 rounded justify-between">
          <div className="flex">
            <p className="font-bold text-brand-800 mr-4">Cart overview</p>
          </div>
          <div className="flex">
            <button className="flex items-center text-center cursor-pointer px-4 py-1 rounded rounded-full text-gray-600 hover:text-red-400 text-sm uppercase mr-4"
              onClick={(event) => clearCart()}>
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                  <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
              </svg>                
              <span className="hidden md:block">Empty cart</span>
            </button>
            {<Checkout disabled={country !== 'usa'} cart={state.cart} />}
          </div>

        </div>
        <div className="w-full flex flex-col items-center " >
          {state.cart.map((item, key) =>
            <div 
             className="w-full border-t border-l border-r last:border-b border-gray-300 flex flex-col md:flex-row items-center bg-white mx-6 md:mx-0 px-4" key={item.id}>
              <Link to={'/product/' + slugify(item.fullItem.name + " " + item.fullItem.id)} 
                  className="flex flex-col md:flex-row items-center px-2 w-full">
                <Img className="m-2 w-48 md:w-24 rounded m-2" fluid={item.fullItem.photos[0].localFile.childImageSharp.fluid} />
                <div>
                  <p>{item.fullItem.name}</p>
                  <p className="font-bold text-sm">{item.quantity} x  ${Math.round(item.price * 100, 2) / 10000} = ${Math.round(item.price * item.quantity * 100, 2) / 10000}</p>
                </div>
              </Link>
              <div className="flex w-1/12" >
                <div onClick={(event) => removeItem(item)} className="m-2 text-gray-300 hover:text-red-400 cursor-pointer">
                     <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                        <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                    </svg>  
                </div>
              </div>
            </div >
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Cart