import React, { useContext, useState } from 'react'
import CartContext from '../context/CartContext'


const Checkout = (props) => {
  // Initialise Stripe.js with your publishable key.
  // You can find your key in the Dashboard:
  // https://dashboard.stripe.com/account/apikeys

  const { state, getTotalValue } = useContext(CartContext)
  const { items, setItems } = useState()

  const getStripe = () => {
    let stripe = window.Stripe(process.env.GATSBY_STRIPE_KEY, {
      betas: ['checkout_beta_4'],
    })
    return stripe
  }

  let redirectToCheckout = async (event, cartItems) => {
    const { error } = await getStripe().redirectToCheckout({
      items: cartItems.map((sku) => {
        return {
          sku: sku.id,
          quantity: sku.quantity
        };
      }),
      successUrl: `https://cobaso.com/thankyou`,
      cancelUrl: `https://cobaso.com/cart/`,
      billingAddressCollection: 'required',

    })
    // console.log('cartItems ', cartItems)

    if (error) {
      console.error('Error:', error)
    }
    setItems(cartItems)
    // console.log('items with hooks', items)

  }

  return (
    <div
      className={
        props.disabled
          ? "px-4 py-2 hover:bg-brand-300 rounded bg-brand-300 text-white font-bold tracking-wider"
          : "px-4 py-2 hover:bg-green-600 rounded bg-green-500 text-white font-bold tracking-wider cursor-pointer"
      }
      onClick={props.disabled ? null : event => redirectToCheckout(event, state.cart)}
    >
      <div>
        {props.disabled 
        ? <span>Pick a country</span>
        : <><span>${getTotalValue()} | &nbsp;</span><span className="font-bold">{state.cart.length ? 'Checkout' : 'Cart is empty'}</span></>}
      </div>
    </div>
  )
}

export default Checkout